.casestudy {
  margin: 0 30px;

  @media only screen and (max-width: 900px) {
    margin: 0;
  }

  .casestudy-container {
    animation: casestudy 2s ease-out;

    @keyframes casestudy {
      0% { opacity: 0;}
      90% { opacity: 0;}
      100%{opacity: 1;}
    }
  }
}
