.menu {
  border-right: 1px solid #3f4242;
  height: 100vh;
  width: 140px;
  padding: 0 24px;
  display: flex;
  flex-direction: column;
  position: fixed;
  background-color: rgba(0, 0, 0,  0.4);


  @media only screen and (max-width: 900px) {
    background-color: black;
    border: none;
    flex-direction: row;
    height: auto;
    left: 0;
    padding: 0;
    top: 0;
    width: 100%;
    z-index: 5;

    h2 {
      padding: 0 15px !important;
      margin: 0;
      top: -5px;
    }

    .links,
    .foot {
      display: none
    }
  }

  a {
    text-decoration: none;
    color: white;
  }

  h2 {
    margin: 0;
    padding: 10px 0 20px;
    font-size: 60px;
    text-decoration: none;

    @media only screen and (max-width: 1000px) {
      font-size: 60px;
      min-height: 55px;
    }
  }

  .links{
    font-size: 18px;
    flex-grow: 1;
    p {
      padding: 5px 0;
      transition: 0.2s ease-in;

      &:hover {
        color: #1004FA;
      }
    }
  }
  .foot {
    margin: 15px 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    display: none;

    @media only screen and (min-width: 900px) {
      display: flex;
    }

    img {
      width: 20px;
      margin-left: 10px;
    }

    a {
      transition: 0.2s ease-in;
      &:hover {
        color: #1004FA;
      }
    }
  }
}
