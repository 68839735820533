.case-content {
  margin: 0 auto;
  max-width: 1400px;
  width: 100%;

  .button {
    align-items: start;
    border: 1px solid white;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    font-family: Akkurat;
    height: 50px;
    justify-content: center;
    margin: 100px auto 200px;
    position: relative;
    text-align: center;
    width: 200px;

    &:hover .button-underlay {
      width: 100%;
    }

    p {
      align-self:center;
      z-index: 2;
    }

    .button-underlay {
      align-self: flex-start;
      background: blue;
      height: 100%;
      position: absolute;
      transition: width 0.2s ease-out;
      width: 0;
    }
  }

  .content-image {
    display: flex;
    justify-content: center;
    width: 100%;


    img {
      border-radius: 2px;
      height: 600px;
      object-fit: cover;
      object-position: center;
      width: 100%;
    }
  }

  .content-info {
    border-top: 1px solid white;
    margin: 50px 0 100px;

    @media only screen and (max-width: 900px) {
      margin: 25px 0 50px;
    }

    h2 {
      font-size: 50px;

      @media only screen and (max-width: 900px) {
        font-size: 30px;
      }
    }

    .description {
      line-height: 1.4;
      max-width: 800px;
      white-space: pre-line;
      width: 100%;

      @media only screen and (max-width: 900px) {
        font-size: 14px;
      }

      img {
        width: 100%;
      }
    }
  }
}
