.gallery {
  display: flex;
  flex-direction: row;
  .spacer {
    height: 100vh;
    width: 220px;
    @media only screen and (max-width: 900px) {
      display: none;
    }
  }

  .photos {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 24px;

    .land {
      margin: 100px 0;
      width: 70%;
      // margin-left: -190px;
    }

    .port {
      margin: 100px 0;
      width: 30%;
    }
  }
}
