.slider {
  align-items: flex-end;
  animation: slideshow 150s linear infinite;
  display: flex;
  flex-direction: column;
  float: right;
  overflow: hidden;
  padding: 0 24px;
  position: relative;
  transform: translate3d(0, 0, 0);
  width: 700px;
  z-index: 0;

  .land {
    margin: 12px 0;
    width: 100%;
  }

  .port {
    margin: 12px 0;
    width: 50%;
  }

  @media only screen and (max-width: 900px) {
    width: 100%;
    padding: 0;
    float: none;
  }

  @keyframes slideshow {
    0% { -webkit-transform: translateY(24px); }
    100%{ -webkit-transform: translateY(-100%); }
  }
}
