.header-img {
  height: 100vh;
  width: 100%;
  animation: headerImg 1s cubic-bezier(.3,.1,.2,1);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;

  @media only screen and (max-width: 900px) {
    animation: headerMob 1s cubic-bezier(.3,.1,.2,1);
    height: 90vh;
  }

  @keyframes headerImg {
    0% { height: 0;}
    100%{ height: 100vh;}
  }

  @keyframes headerMob {
    0% { height: 0;}
    100%{ height: 90vh;}
  }

  .back {
    padding: 10px 0;
  }

  .caption {
    color: white;
    font-size: 12px;
    align-self: start;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    bottom: 0;
    z-index: 10;
    margin: 0 auto;
    max-width: 1400px;
  }

  .ani {
    align-items: center;
    border-radius: 2px;
    align-self: center;
    background-color: #0000ff;
    display: flex;
    flex-direction: column;
    height: 75%;
    justify-content: center;
    width: 100%;
    max-width: 1400px;

    h2 {
      font-size: 6vw;
      z-index: 1;
    }

    img {
      width: 100%;
      position: absolute;
      z-index: 0;
      opacity: 0.1;

      @media only screen and (max-width: 900px) {
        object-fit: cover;
        object-position: center top;
        height: 100%;
      }
    }
  }
}
