.footer {
  position: fixed;
  bottom: 50px;
  right: 410px;
  font-size: 14px;

  @media only screen and (max-width: 900px) {
    left: 24px;
    width: 150px;
  }

  .socials{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    img {
      width: 20px;
      padding-left: 15px;
    }
  }

  a {
    text-decoration: none;
    color: white;
    transition: 0.2s ease-in;

    &:hover {
      color: blue;
    };
  }

  .work {
    margin-top: 50px;
  }
}
