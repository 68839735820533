.hamburger {
  top: 18px;
  right: 15px;
  width: 30px;
  position: absolute;
}

.ham {
  display: none;

  @media only screen and (max-width: 900px) {
    display: block;
    width: 25px;
    height: 25px;
    z-index: 11;
    position: absolute;
  }
}

.mobile-menu {
  align-items: center;
  background-color: black;
  display: flex;
  flex-direction: column;
  font-size: 40px;
  height: 100%;
  justify-content: center;
  left: 0;
  opacity: 0;
  pointer-events: none;
  position: fixed;
  text-align: center;
  top: 0;
  transition: 0.15s ease-in;
  width: 100vw;
  z-index: -1;

  a {
    text-decoration: none;
    color: white;
  }

  p{
    margin: 15px 0;
  }
}
