.about {
  display: flex;
  flex-direction: row;

  .spacer {
    height: 100vh;
    width: 220px;

    @media only screen and (max-width: 900px) {
        display: none;
    }
  }

  .about-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin: 0 150px 0 100px;

    @media only screen and (max-width: 900px) {
      margin: 100px 0;
      flex-direction: column-reverse;
      justify-content: center;
      align-items: center;

      img {
        display: none;
      }
    }

    img {
      width: 200px;
    }
  }

  .about-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;

    h2 {
      font-size: 50px;
      max-width: 900px;

      @media only screen and (max-width: 900px) {
        font-size: 32px;
        max-width: 100%;
      }
    }

    .row {
      width: 80%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      @media only screen and (max-width: 900px) {
        width: 100%;
        flex-direction: column;
      }

      p {
        max-width: 47%;
        font-size: 14px;

        @media only screen and (max-width: 900px) {
          max-width: 100%;
        }
      }
    }

    a {
      font-size: 14px;
      margin: 20px 0;
      @media only screen and (max-width: 900px) {
        margin: 50px 0;
        align-self: center;
        font-size: 18px;
      }
    }
  }
}
