a {
  color: white;
  text-decoration: none;
}

.project {
  border-top: 1px solid #3f4242;

  cursor: pointer;
  display: flex;
  flex-direction: row;
  height: 400px;
  padding: 15px 0;
  width: 100%;


  @media only screen and (max-width: 900px) {
    height: calc(100vw / 2);
  }


  &:hover {
    h2 {color: blue}

    p {color: blue;}
  }

  .info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 50%;

    .mob-link {
      @media only screen and (max-width: 900px) {
        height: 100%;
      }
    }

    .project-title {
      h2 {
        font-size: 40px;
        margin: 0 0 5px;
        transition: 0.2s ease-in;
      }
      p {
        font-size: 14px;
        transition: 0.2s ease-in;
      }
    }

    .view-project {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      a {
        color: white;
        align-self: flex-end;
        transition: 0.2s ease-in;

        &:hover {
          color: blue;
        }
      }

      .client {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        align-self: flex-end;
        width: 300px;

        p {
          margin: 15px 0 0  ;
          transition: 0.2s ease-in;
        }

        .above {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          width: 100%;

        }

        .below {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          width: 100%;
        }

      }
    }

    @media only screen and (max-width: 900px) {
      height: calc((100vw / 2) - 10px);
      padding: 0 10px;
      position: absolute;
      width: calc(100% - 20px);

      .view-project a{
        display: none;
      }

      .view-project {
        .client {
          width: 100%;
          font-size: 12px;
        }
      }

      .title {
        h2 {
          font-size: 30px;
        }
        p {
          font-size: 12px;
          transition: 0.2s ease-in;
          margin: 0;
        }
      }
    }
  }

  .link {
    height: 100%;
    margin-left: 24px;
    width: 50%;

    @media only screen and (max-width: 900px) {
      margin: 0;
      width: 100%;
    }
  }
  .image {
    height: 100%;
    width: 100%;
    animation: initImage 1.4s cubic-bezier(.3,.1,.2,1);
    transition-timing-function: cubic-bezier(.3,.1,.2,1);

    @keyframes initImage {
      0% { background-size:  130%; }
      100%{ background-size: 100%; }
    }

    .overlay {
      animation: initOverlay 1.4s cubic-bezier(.3,.1,.2,1);
      transition-timing-function: cubic-bezier(.3,.1,.2,1);
      background-color: blue;
      float: right;
      width: 0%;
      height: 100%;

      @keyframes initOverlay {
        0% { width: 100%;}
        100%{ width: 0%;}
      }
    }

    @media only screen and (max-width: 900px) {
      width: 100%;
      margin: 0;
    }
  }
}
