.projects {
  height: 100%;
  display: flex;
  flex-direction: row;

  .spacer {
    height: 100vh;
    width: 220px;

    @media only screen and (max-width: 900px) {
      display: none;
    }
  }

  .projects-list {
    margin: 300px 24px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #3f4242;

    @media only screen and (max-width: 900px) {
      margin: 200px 0;
    }
  }
}
