@font-face {
  font-family: 'Helvetica';
  src: url("./assets/fonts/Helvetica.woff2") format("woff2"),
       url("./assets/fonts/Helvetica.woff") format("woff");
       -webkit-font-smoothing: antialiased;
       text-rendering: optimizeLegibility;
       font-smooth: always;
}

@font-face {
  font-family: 'Helvetica-Bold';
  src: url('./assets/fonts/Helvetica-Bold.woff2') format("woff2"),
       url('./assets/fonts/Helvetica-Bold.woff') format("woff");
       -webkit-font-smoothing: antialiased;
       text-rendering: optimizeLegibility;
       font-smooth: always;
}

@font-face {
  font-family: Akkurat;
  src:url("https://d33wubrfki0l68.cloudfront.net/9f02a726216e5782250ec9dc5a23397ff9e61477/c8999/static/fonts/akkurat/akkuratmonollweb-regular.woff") format("woff"),
  url("https://d33wubrfki0l68.cloudfront.net/230f284e1b4f73d5e1af3fed889650bd4f0c1891/ff891/static/fonts/akkurat/akkuratmonollweb-regular.woff2") format("woff2");
  font-weight:400;
  font-style:normal
}

html,
body {
  background: #000000;
  color: white;
  margin: 0;
  overflow-x: hidden;
  scroll-behavior: smooth;
}

body::-webkit-scrollbar {
  display: none;
}

.App {
  color: white;
  min-height: 100vh;
  position: relative;
  animation: loadApp 1.4s cubic-bezier(.3,.1,.2,1);

  p, a {
      // font-family: Akkurat, sans-serif;
      font-weight: light;
  }


  @keyframes loadApp {
    0% { opacity: 0;}
    100%{opacity: 1;}
  }

  @media only screen and (max-width: 900px) {
    margin: 0 15px;
  }
}
