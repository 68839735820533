.subtitle {
  width: 2500%;
  margin: 0 auto;
  height: 200px;

  p {
    font-size: 100px;
    transition: transform 0.4s ease-out;
    margin: 0;
  }
}
