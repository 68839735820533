.header {
  position: fixed;
  top: 0;
  width: 76%;
  z-index: 5;

  @media only screen and (max-width: 900px) {
    left: -15px;
    padding: 0 15px;
    width: 100%;
  }
}

.nav {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: 1800px;
  position: sticky;
  top: 0;
  width: 100%;
}

.nav-head {
  display: flex;
  flex-direction: column;
  padding: 0 50px;
  width: 100%;
  min-width: 300px;

  @media only screen and (max-width: 900px) {
    padding: 0 15px;
  }

  .nav-title {
    font-size: 150px;
    margin: 0;

    @media only screen and (max-width: 900px) {
      font-size: 70px;
      width: 190px;
    }
  }

  p {
    font-size: 18px;
    font-weight: 400;
    width: 100%;
    margin: 0 0 30px;

    @media only screen and (max-width: 900px) {
      margin-bottom: 20px;
      font-size: 14px;
    }
  }

  .location {
    font-size: 14px;

    @media only screen and (max-width: 900px) {
      font-size: 12px;
    }
  }
}

ul {
  display: flex;
  justify-content: space-between;
  list-style-type: none;
  width: 300px;
  margin-top: 50px;

  @media only screen and (max-width: 900px) {
    display: none;
  }
}

li {
  color: white;
  float: left;
  font-size: 18px;
  padding: 0 10px;
  transition: 0.2s ease-in;
}

li:hover {
  color: #1004FA;
}

li a {
  text-decoration: none;
}

li img {
  margin-right: 30px;
  transform: translateY(-35%);
  width: 50px;
}
