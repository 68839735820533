.tech {
  max-width: 1400px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: start;

  p {
    font-family: Akkurat;
    font-size: 12px;
    margin-right: 20px;
  }
}
